import { useEffect, useRef } from "react";
import styles from "./Button.module.scss";
import clsx from "clsx";

const Button = ({
  type = "button",
  variant = "filled",
  fullWidth = false,
  size = "sizeL",
  className,
  onClick,
  disabled,
  ariaDisabled,
  children,
  animated = false, // Option for animations
}) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (!animated || !buttonRef.current) return;

    // Colors for airdrop theme - gold, violet, and blue hues
    const particleColors = [
      "#FFD700", // Gold
      "#FFC107", // Amber
      "#E1BEE7", // Light Purple
      "#9C27B0", // Deep Purple
      "#3F51B5", // Indigo
      "#29B6F6", // Light Blue
    ];

    // Create spectacular burst effect with particles from various positions
    const createBurstEffect = () => {
      const button = buttonRef.current;
      if (!button) return;

      const rect = button.getBoundingClientRect();

      // Create multiple bursts from different positions
      for (let j = 0; j < 5; j++) {
        // Generate random positions across the button
        const randomX = rect.left + Math.random() * rect.width;
        const randomY = rect.top + Math.random() * rect.height;

        // Create particles in a burst pattern from this point
        for (let i = 0; i < 6; i++) {
          setTimeout(() => {
            if (buttonRef.current) {
              const particle = document.createElement("div");
              document.body.appendChild(particle);

              // Random size
              const size = 6 + Math.random() * 8;

              // Random color from our airdrop theme
              const colorIndex = Math.floor(
                Math.random() * particleColors.length,
              );
              const color = particleColors[colorIndex];

              // Style particle
              particle.style.position = "fixed";
              particle.style.width = `${size}px`;
              particle.style.height = `${size}px`;
              particle.style.borderRadius = "50%";
              particle.style.background = color;
              particle.style.left = `${randomX}px`;
              particle.style.top = `${randomY}px`;
              particle.style.pointerEvents = "none";
              particle.style.zIndex = "1000";
              particle.style.boxShadow = `0 0 10px ${color}`;
              particle.style.filter = "blur(1px)";

              // Random direction from this burst point
              const degree = i * 60 + Math.random() * 30;
              const distance = 60 + Math.random() * 60;
              const radians = degree * (Math.PI / 180);
              const destinationX = randomX + distance * Math.cos(radians);
              const destinationY = randomY + distance * Math.sin(radians);

              // Animated path
              const animation = particle.animate(
                [
                  {
                    transform: "scale(0)",
                    opacity: 0,
                  },
                  {
                    transform: `scale(${1 + Math.random() * 0.5})`,
                    opacity: 1,
                    offset: 0.1,
                  },
                  {
                    transform: `translate(${destinationX - randomX}px, ${
                      destinationY - randomY
                    }px) scale(0)`,
                    opacity: 0,
                  },
                ],
                {
                  duration: 1200 + Math.random() * 800,
                  easing: "cubic-bezier(0.1, 0.8, 0.2, 1)",
                  fill: "forwards",
                },
              );

              animation.onfinish = () => {
                particle.remove();
              };
            }
          }, i * 40); // Staggered creation within each burst
        }
      }

      // Add a central glow
      addCentralGlow(button);
    };

    // Create particles across the button surface
    const createParticle = (button) => {
      if (!button) return;

      const rect = button.getBoundingClientRect();

      // Generate random position within the button
      const offsetX = Math.random() * rect.width;
      const offsetY = Math.random() * rect.height;
      const x = rect.left + offsetX;
      const y = rect.top + offsetY;

      const particle = document.createElement("div");
      document.body.appendChild(particle);

      // Smaller size for subtlety
      const size = 4 + Math.random() * 6;

      // Random color from our airdrop theme
      const colorIndex = Math.floor(Math.random() * particleColors.length);
      const color = particleColors[colorIndex];

      // Style the particle
      particle.style.position = "fixed";
      particle.style.width = `${size}px`;
      particle.style.height = `${size}px`;
      particle.style.borderRadius = "50%";
      particle.style.background = color;
      particle.style.left = `${x}px`;
      particle.style.top = `${y}px`;
      particle.style.pointerEvents = "none";
      particle.style.zIndex = "1000";
      particle.style.boxShadow = `0 0 8px ${color}`;
      particle.style.filter = "blur(1px)";

      // Calculate random direction
      const degree = Math.random() * 360;
      const distance = 50 + Math.random() * 40;
      const radians = degree * (Math.PI / 180);
      const destinationX = x + distance * Math.cos(radians);
      const destinationY = y + distance * Math.sin(radians);

      // Create smooth motion path
      const bezierX =
        x + distance * 0.5 * Math.cos(radians + (Math.random() * 0.3 - 0.15));
      const bezierY =
        y + distance * 0.5 * Math.sin(radians + (Math.random() * 0.3 - 0.15));

      // Animate with curved path and slow speed
      const duration = 2500 + Math.random() * 1000;

      const keyframes = [
        {
          transform: "scale(0)",
          opacity: 0,
        },
        {
          transform: `scale(${1 + Math.random() * 0.5})`,
          opacity: 1,
          offset: 0.1,
        },
        {
          transform: `translate(${bezierX - x}px, ${bezierY - y}px) scale(${
            1 + Math.random() * 0.3
          })`,
          opacity: 0.8,
          offset: 0.4 + Math.random() * 0.2,
        },
        {
          transform: `translate(${destinationX - x}px, ${
            destinationY - y
          }px) scale(0)`,
          opacity: 0,
        },
      ];

      const animation = particle.animate(keyframes, {
        duration,
        easing: "cubic-bezier(0.1, 0.8, 0.2, 1)", // More dynamic easing
        fill: "forwards",
      });

      // Remove particle after animation completes
      animation.onfinish = () => {
        particle.remove();
      };
    };

    // Add a central glow effect to emphasize the button
    const addCentralGlow = (button) => {
      const glow = document.createElement("div");
      button.appendChild(glow);

      // Position glow in center of button
      glow.style.position = "absolute";
      glow.style.top = "50%";
      glow.style.left = "50%";
      glow.style.transform = "translate(-50%, -50%)";
      glow.style.width = "60%";
      glow.style.height = "60%";
      glow.style.borderRadius = "50%";
      glow.style.pointerEvents = "none";

      // Animate the glow
      const animation = glow.animate(
        [
          {
            boxShadow: "0 0 0 rgba(255, 215, 0, 0)",
            background: "rgba(255, 215, 0, 0)",
          },
          {
            boxShadow: "0 0 30px rgba(255, 215, 0, 0.7)",
            background: "rgba(255, 215, 0, 0.3)",
          },
          {
            boxShadow: "0 0 5px rgba(255, 215, 0, 0)",
            background: "rgba(255, 215, 0, 0)",
          },
        ],
        {
          duration: 1200,
          easing: "ease-out",
          fill: "forwards",
        },
      );

      animation.onfinish = () => {
        glow.remove();
      };
    };

    // Create floating coins/tokens around the button
    const createFloatingTokens = () => {
      const button = buttonRef.current;
      if (!button) return;

      const rect = button.getBoundingClientRect();

      for (let i = 0; i < 3; i++) {
        const token = document.createElement("div");
        document.body.appendChild(token);

        // Random position on the button surface
        const x = rect.left + Math.random() * rect.width;
        const y = rect.top + Math.random() * rect.height;

        // Style token like a coin/airdrop token
        token.style.position = "fixed";
        token.style.width = "18px";
        token.style.height = "18px";
        token.style.borderRadius = "50%";
        token.style.background =
          "radial-gradient(circle, #FFD700 30%, #FFA000 100%)";
        token.style.boxShadow = "0 0 10px rgba(255, 215, 0, 0.8)";
        token.style.left = `${x}px`;
        token.style.top = `${y}px`;
        token.style.pointerEvents = "none";
        token.style.zIndex = "999";

        // Create coin-like details
        const innerCircle = document.createElement("div");
        token.appendChild(innerCircle);
        innerCircle.style.position = "absolute";
        innerCircle.style.top = "50%";
        innerCircle.style.left = "50%";
        innerCircle.style.transform = "translate(-50%, -50%)";
        innerCircle.style.width = "70%";
        innerCircle.style.height = "70%";
        innerCircle.style.borderRadius = "50%";
        innerCircle.style.border = "1px solid rgba(255, 255, 255, 0.5)";

        // Animate the floating motion
        const duration = 1500 + Math.random() * 1000;
        const floatDistance = 20 + Math.random() * 15;

        // Create float and fade animation
        const animation = token.animate(
          [
            {
              transform: `translate(0, 0) rotate(0deg)`,
              opacity: 0,
            },
            {
              transform: `translate(${
                Math.random() * 10 - 5
              }px, -${floatDistance}px) rotate(${Math.random() * 180}deg)`,
              opacity: 1,
              offset: 0.3,
            },
            {
              transform: `translate(${Math.random() * 20 - 10}px, -${
                floatDistance * 1.8
              }px) rotate(${Math.random() * 360}deg)`,
              opacity: 0,
            },
          ],
          {
            duration: duration * 1.3, // Slower token floating
            easing: "ease-out",
            fill: "forwards",
          },
        );

        animation.onfinish = () => {
          token.remove();
        };
      }
    };

    // Click effect with ripple and particles
    const handleClick = (e) => {
      createBurstEffect();
      createFloatingTokens();

      // Create ripple effect
      const button = buttonRef.current;
      if (!button) return;

      const rect = button.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      // Create ripple element
      const ripple = document.createElement("div");
      button.appendChild(ripple);

      ripple.style.position = "absolute";
      ripple.style.width = "0";
      ripple.style.height = "0";
      ripple.style.borderRadius = "50%";
      ripple.style.backgroundColor = "rgba(255, 255, 255, 0.4)";
      ripple.style.transform = "translate(-50%, -50%)";
      ripple.style.left = `${x}px`;
      ripple.style.top = `${y}px`;

      // Animate ripple
      ripple.animate(
        [
          { width: "0", height: "0", opacity: 0.8 },
          {
            width: `${rect.width * 2.5}px`,
            height: `${rect.width * 2.5}px`,
            opacity: 0,
          },
        ],
        {
          duration: 800,
          easing: "cubic-bezier(0, 0.5, 0.25, 1)",
          fill: "forwards",
        },
      ).onfinish = () => ripple.remove();
    };

    // Continuous gentle animation instead of attention-grabbing bursts
    const startContinuousAnimation = () => {
      const button = buttonRef.current;
      if (!button) return;

      // Delayed start to ensure button is properly rendered
      setTimeout(() => {
        addCentralGlow(button);
      }, 500);

      // Continuous gentle animations with controlled frequency
      let animationCount = 0;
      const animationInterval = setInterval(() => {
        animationCount++;

        // Enhanced button glow animation with more subtle floating effect
        button.animate(
          [
            {
              boxShadow: "0 0 10px 3px rgba(70, 18, 152, 0.3)",
              transform: "translateY(0)",
            },
            {
              boxShadow: "0 0 15px 5px rgba(70, 18, 152, 0.5)",
              transform: "translateY(-3px)", // Reduced floating height significantly
            },
            {
              boxShadow: "0 0 10px 3px rgba(70, 18, 152, 0.3)",
              transform: "translateY(0)",
            },
          ],
          {
            duration: 3000,
            easing: "ease-in-out",
          },
        );

        // Create particles from various points across the button
        for (let i = 0; i < 4; i++) {
          setTimeout(() => {
            if (buttonRef.current) {
              createParticle(button);
            }
          }, i * 250); // Stagger particle creation
        }

        // Create floating tokens more frequently
        if (animationCount % 3 === 0) {
          setTimeout(() => {
            if (buttonRef.current) {
              createFloatingTokens();
            }
          }, 700);
        }
      }, 2800); // More frequent animations but still gentle

      return () => clearInterval(animationInterval);
    };

    // Initialize continuous animations
    const cleanupAnimation = startContinuousAnimation();

    // Add event listener for click only (no hover)
    const button = buttonRef.current;
    button.addEventListener("click", handleClick);

    // Cleanup on unmount
    return () => {
      if (cleanupAnimation) cleanupAnimation();
      if (button) {
        button.removeEventListener("click", handleClick);
      }
    };
  }, [animated]);

  const handleClick = (e) => {
    if (onClick) onClick(e);
  };

  return (
    <button
      ref={buttonRef}
      type={type}
      onClick={handleClick}
      disabled={disabled}
      aria-disabled={ariaDisabled}
      className={clsx(
        styles.btn,
        styles[variant],
        styles[size],
        fullWidth && styles.fullWidth,
        animated && styles.airdropBtn, // Using a specific class for airdrop
        className && className,
      )}
    >
      {children}
    </button>
  );
};

export default Button;
