import { useCallback, useEffect, useRef, useState } from "react";
import useApi from "../../../../api/rest";

const CLICK_CHECK_INTERVAL = 2000;
const CLICK_PER_SECOND_LIMIT = 20;
const JOIN_NEXT_COORDINATES_SAMPLE_LEN = 3;
const SAME_CLICK_THRESHOLD = 3;
const TRAP_VISIBLE_DURATION = 100000000;
const MAX_TRAP_CLICKS = 3;

export const useClickerDetector = () => {
  const { sendAdditionalInf } = useApi();

  const clickCountRef = useRef(0);
  const notTrustedClickCountRef = useRef(0);
  const cpsLimitExceededRef = useRef(null);
  const buttonTypeRef = useRef("unknown");

  const hasSentUntrustedClickRef = useRef(false);

  const [isShowTrap, setIsShowTrap] = useState(false);
  const trapClickCountRef = useRef(0);
  const banByTrapTimerIdRef = useRef(null);
  const hasClickedOnRealButtonAfterTrapRef = useRef(false);

  const claimButtonClickCoordinatesRef = useRef([]);
  const trapClickCoordinatesRef = useRef([]);

  const sendCheatersData = useCallback(
    (data) => {
      if (data.reason === "NotTrustedClicks") {
        if (hasSentUntrustedClickRef.current) {
          return;
        }
        hasSentUntrustedClickRef.current = true;
      }

      try {
        sendAdditionalInf(data).catch((error) => {});
      } catch (e) {}
    },
    [sendAdditionalInf],
  );

  const resetTrapState = useCallback(() => {
    if (banByTrapTimerIdRef.current) {
      clearTimeout(banByTrapTimerIdRef.current);
    }

    banByTrapTimerIdRef.current = null;
    setIsShowTrap(false);
    trapClickCountRef.current = 0;
    claimButtonClickCoordinatesRef.current = [];
    trapClickCoordinatesRef.current = [];
    hasClickedOnRealButtonAfterTrapRef.current = false;
  }, []);

  const tapClickerDetector = useCallback(
    (event) => {
      clickCountRef.current++;

      if (!event.isTrusted) {
        notTrustedClickCountRef.current++;

        sendCheatersData({
          reason: "NotTrustedClicks",
          type: "tapButton",
          clicks: notTrustedClickCountRef.current,
          timestamp: new Date().toISOString(),
        });
      }
    },
    [sendCheatersData],
  );

  const btnClickerDetector = useCallback(
    (event, type = "claimButton") => {
      buttonTypeRef.current = type;

      if (!isShowTrap && event.isTrusted) {
        const rect = event?.target?.getBoundingClientRect?.() || {
          left: 0,
          top: 0,
        };
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        claimButtonClickCoordinatesRef.current = [
          ...claimButtonClickCoordinatesRef.current.slice(
            -(JOIN_NEXT_COORDINATES_SAMPLE_LEN - 1),
          ),
          { x, y },
        ];

        if (
          claimButtonClickCoordinatesRef.current.length >=
          JOIN_NEXT_COORDINATES_SAMPLE_LEN
        ) {
          if (
            areClicksInSameArea(
              claimButtonClickCoordinatesRef.current,
              SAME_CLICK_THRESHOLD,
            )
          ) {
            setTimeout(() => setIsShowTrap(true), 1000);
          }
        }
      }

      if (isShowTrap) {
        hasClickedOnRealButtonAfterTrapRef.current = true;
      }

      if (!event.isTrusted) {
        sendCheatersData({
          reason: "NotTrustedClicks",
          type,
          timestamp: new Date().toISOString(),
        });
      }
    },
    [isShowTrap, sendCheatersData],
  );

  const sendTrapCheaterData = useCallback(() => {
    sendCheatersData({
      reason: "ClickSameArea",
      type: buttonTypeRef.current,
      trapClickCount: trapClickCountRef.current,
      claimClickCoordinates: claimButtonClickCoordinatesRef.current,
      trapClickCoordinates: trapClickCoordinatesRef.current,
      hasClickedOnRealButtonAfterTrap:
        hasClickedOnRealButtonAfterTrapRef.current,
      timestamp: new Date().toISOString(),
    });
  }, [sendCheatersData]);

  const onTrapClickDetector = useCallback(
    (event) => {
      trapClickCountRef.current++;

      const rect = event?.target?.getBoundingClientRect?.() || {
        left: 0,
        top: 0,
      };
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      trapClickCoordinatesRef.current.push({ x, y });

      if (trapClickCountRef.current >= MAX_TRAP_CLICKS) {
        sendTrapCheaterData();
        resetTrapState();
        return;
      }

      if (!banByTrapTimerIdRef.current) {
        banByTrapTimerIdRef.current = setTimeout(() => {
          sendTrapCheaterData();
          resetTrapState();
        }, TRAP_VISIBLE_DURATION);
      }
    },
    [resetTrapState, sendTrapCheaterData],
  );

  useEffect(() => {
    let lastTimestamp = Date.now();

    const timer = setInterval(() => {
      const currentTimestamp = Date.now();
      const diffSec = (currentTimestamp - lastTimestamp) / 1000;

      const clicksPerSec = clickCountRef.current / diffSec;

      clickCountRef.current = 0;
      lastTimestamp = currentTimestamp;

      if (clicksPerSec > CLICK_PER_SECOND_LIMIT) {
        const totalCPS = parseFloat(clicksPerSec.toFixed(2));

        if (
          !cpsLimitExceededRef.current ||
          cpsLimitExceededRef.current.totalCPS < totalCPS
        ) {
          cpsLimitExceededRef.current = {
            totalCPS,
            timestamp: new Date().toISOString(),
          };

          sendCheatersData({
            reason: "CPSLimitExceeded",
            totalCPS,
            timestamp: new Date().toISOString(),
          });
        }
      }
    }, CLICK_CHECK_INTERVAL);

    return () => {
      clearInterval(timer);
    };
  }, [sendCheatersData]);

  return {
    tapClickerDetector,
    btnClickerDetector,
    onTrapClickDetector,
    isShowTrap,
    resetTrapState,
  };
};

function areClicksInSameArea(clicks, baseThreshold) {
  if (clicks.length < 2) return true;

  const devicePixelRatio = window.devicePixelRatio || 1;
  const threshold = baseThreshold * devicePixelRatio;

  const { x: firstClickX, y: firstClickY } = clicks[0];

  return clicks.every(({ x, y }) => {
    const distance = Math.sqrt((x - firstClickX) ** 2 + (y - firstClickY) ** 2);

    return distance <= threshold;
  });
}
