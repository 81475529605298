import styles from "./AirdropModal.module.scss";

import React, { useCallback } from "react";

import ModalWrapper from "../../base/ModalWrapper/ModalWrapper";
import Text from "../../base/Text/Text";
import Button from "../../base/Button/Button";
import { useTelegram } from "../../../context/TelegramProvider";
import useApi from "../../../api/rest";

import Loader from "../../Loader/Loader";

export const AirdropModal = ({ onClose }) => {
  const { webApp } = useTelegram();

  const { getBecexyAirdropLink } = useApi();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleBecexyPortalClick = useCallback(async () => {
    setIsLoading(true);

    try {
      const data = await getBecexyAirdropLink();

      console.log("data", data);

      if (data && data.otpLink) {
        if (webApp) {
          webApp.openLink(data.otpLink);
        } else {
          window.open(data.otpLink, "_blank");
        }
      } else {
        console.error("No otpLink received from API");
      }
    } catch (error) {
      console.error("Error getting BeCEXY airdrop link:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getBecexyAirdropLink, webApp]);

  return (
    <ModalWrapper
      onClose={onClose}
      className={styles.airdropModal}
      headerClassName={styles.header}
      header={<br></br>}
    >
      <span className={styles.title}>
        <Text variant="h1" weight="bold" color="gradient">
          The Wigwam<br></br>DRUM Airdrop
        </Text>
      </span>
      <div className={styles.airdropContent}>
        <Text
          variant="textM"
          weight="bold"
          align="center"
          className={styles.topInfo}
        >
          The Wigwam DRUM Airdrop will be held on the<br></br>BeCEXY portal.
          Please register to participate
        </Text>
        <div className={styles.imageWrapper}>
          <div className={styles.glowingEllipse}></div>
          <img
            src="/img/airdrop.avif"
            alt="Airdrop"
            width={220}
            height={220}
            className={styles.airdropImage}
          />
        </div>
        <Button
          size="sizeS"
          className={styles.airdropBtn}
          onClick={handleBecexyPortalClick}
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <img
                src="/img/becexyL.png"
                alt="BeCEXY logo"
                width={32}
                height={32}
                className={styles.logo}
              />
              <Text variant="textL" weight="bold">
                BeCEXY portal
              </Text>
            </>
          )}
        </Button>
        <Text
          variant="textM"
          weight="bold"
          align="center"
          className={styles.info}
        >
          Follow BeCEXY's channels to stay informed<br></br>about the upcoming
          airdrop event
        </Text>
        <div className={styles.socials}>
          <Button
            size="sizeS"
            className={styles.tgBtn}
            onClick={() =>
              window.open("https://t.me/BeCEXY_Official", "_blank")
            }
          >
            <img
              src="/img/tg.png"
              alt="Telegram"
              width={40}
              height={40}
              className={styles.tgImage}
            />
            <Text variant="textL" weight="semiBold">
              Telegram
            </Text>
          </Button>
          <Button
            size="sizeS"
            className={styles.twitterBtn}
            onClick={() =>
              window.open("https://x.com/BeCEXY_Official", "_blank")
            }
          >
            <img
              src="/img/x.png"
              alt="Twitter"
              width={40}
              height={40}
              className={styles.twitterImage}
            />
            <Text variant="textL" weight="semiBold">
              Twitter
            </Text>
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export const AirdropModalContext = React.createContext(null);

export const AirdropModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleModalState = (val) => {
    setIsOpen(val);
    if (val) {
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  return (
    <AirdropModalContext.Provider
      value={{ isOpen, setIsOpen: handleModalState }}
    >
      {children}
      {isOpen && <AirdropModal onClose={() => handleModalState(false)} />}
    </AirdropModalContext.Provider>
  );
};
